const CtaOne = () => {
    return (
        <section className="cta-wrapper cta-theme-bg">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8 col-xl-7 offset-xl-1 col-12">
                        <div className="cta-text align-items-center d-md-flex text-center text-md-start">
                            <i class="flaticon-operation"></i>
                            <h2><span style={{fontSize: '1.2rem'}}>Procurando por uma solução para sua empresa?</span> <br /> Fale com nossos especialistas!</h2>
                        </div>
                    </div>
                    <div className="col-md-4 text-center text-md-end">
                        <a href="https://wa.me/5511953967716?text=Olá,%20gostaria%20de%20falar%20com%20um%20especialista." rel="noreferrer" target={'_blank'} className="theme-btn border-btn text-center" style={{fontSize: '1.2rem', paddingRight: '32px', paddingLeft: '32px', }}><i class="fab fa-whatsapp" style={{fontSize: '1.7rem', marginRight: '0.5rem',}}></i> Conversar no WhatsApp</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaOne;
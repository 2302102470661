import { Link } from "react-router-dom";
import servicesOneData from "./servicesOneData";
import alimentacao from "../../assets/img/services/alimentacao.png";
import envase from "../../assets/img/services/envase.png";
import espiral from "../../assets/img/services/espiral.png";

const ServicesOne = () => {
    return (
        <section className="our-service-wrapper section-padding bg-gray" style={{backgroundColor: '#000'}}>
        <div className="container">
            <div className="row mtm-30">
                <div className="col-md-6 col-12 col-lg-6 mt-30">
                    <div className="block-contents">
                        <div className="section-title">
                            <span>A SOLUÇÃO QUE SUA EMPRESA PRECISA</span>
                            <h2>Desenvolvimentos e Projetos</h2>
                        </div>
                        <p>Contamos com uma sólida experiência com mais de 10 anos no segmento de manipulação e movimentação de produtos e embalagens.</p>
                    </div>
                </div>
            </div>
            <div className="row" style={{paddingTop: '2rem',}}>    
                {
                    servicesOneData.map((data) => (
                        <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id} style={{backgroundColor: '#000'}}>
                            <div className="single-service-box style-1" style={{backgroundColor: '#000'}}>
                                <div className="service-bg bg-cover" style={{backgroundColor: '#f5f5f5'}}></div>
                                <div className="icon">
                                    { data.icon === 'custom-balanca' || data.icon === 'custom-envase' || data.icon === 'custom-transportador' ? 
                                        <img width={'100px'} style={{borderRadius: '50%', marginBottom: '1rem'}} height={'100px'} src={data.icon === 'custom-balanca' ? alimentacao : data.icon === 'custom-envase' ? envase : data.icon === 'custom-espiral' ? null : espiral} alt='icone'/>
                                      :
                                        <i className={`flaticon-${data.icon}`}></i>
                                    }
                                </div>
                                <div className="contents">
                                    <h4><Link to="/servicesDetails">{data.title}</Link></h4>
                                    <p>{data.desc}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>
    )
}

export default ServicesOne;
const HeroSocials = () => {
    return (
        <>
        <div className="hero-social-elements d-none d-xxl-block">
        <div className="flp-text">
            <p>Siga-nos</p>
        </div>
        <div className="long-arrow"></div>
        <div className="social-link">
            <a href="https://www.facebook.com/profile.php?id=100088733146088" rel="noreferrer" target={'_blank'}><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/hpackmaquinas/" rel="noreferrer" target={'_blank'}><i className="fab fa-instagram"></i></a>
            <a href="https://www.linkedin.com/company/h-packmaquinas/about/?viewAsMember=true" rel="noreferrer" target={'_blank'}><i className="fab fa-linkedin-in"></i></a>
        </div>
    </div>
        </>
    )
}

export default HeroSocials
import coreBg from '../../assets/img/home1/core-bg.jpg';

const FeaturesOne = () => {
    return (
        <section className="core-features-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-6 pe-lg-0 col-12">
                        <div className="block-contents">
                            <div className="section-title">
                                <span>Porque nos escolher</span>
                                <h2 style={{color: '#000'}}>APRESENTAMOS RESULTADOS</h2>
                            </div>
                        </div>
                        <div className="single-icon-circle-item">
                            <div className="icon">
                                <i className="flaticon-operation"></i>
                            </div>
                            <div className="contents">
                                <h3 style={{color: '#000'}}>SEU PROJETO COM A ATENÇÃO E SOLUÇÕES QUE ELE MERECE</h3>
                                <p style={{color: '#000'}}>Fazemos o estudo de viabilidade e apresentamos as melhores soluções para seu projeto,
                                    considerando as melhores aplicações para sua linha de produção.</p>
                            </div>
                        </div>
                        <div className="single-icon-circle-item">
                            <div className="icon">
                                <i className="flaticon-sketch"></i>
                            </div>
                            <div className="contents">
                                <h3 style={{color: '#000'}}>SOLUCIONAR O PROBLEMA DA SUA EMPRESA É NOSSA PRIORIDADE</h3>
                                <p style={{color: '#000'}}>Trabalhamos sempre parar criar e proporcionar soluções para nossos clientes. Com a atenção 
                                e prioridade que sua empresa merece.</p>
                            </div>
                        </div>
                        <div className="single-icon-circle-item">
                            <div className="icon">
                                <i className="flaticon-24-hours"></i>
                            </div>
                            <div className="contents">
                                <h3 style={{color: '#000'}}>SEMPRE ATUALIZADO</h3>
                                <p style={{color: '#000'}}>Enviamos o nosso cronograma de fabricação para que nossos clientes acompanhem o processo de fabricação de seus projetos do começo ao fim. Atualizando os status 
                                    semanalmente para mantê-lo sempre atualizado.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="core-bg d-none d-xl-block bg-cover" style={{backgroundImage: `url(${coreBg})`}}></div>
        </section>
    )
}

export default FeaturesOne;
import React from 'react'
import { Link } from 'react-router-dom'

const MobileMenu = ({mobileMenu, handleMobileMenu}) => {
  return (
    <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
        <button onClick={handleMobileMenu} type="button" className="close-nav">
            <i className="fal fa-times-circle"></i>
        </button>
        <nav className="sidebar-nav">
            <ul className="metismenu" id="mobile-menu">
                <li><label className="has-arrow" style={{fontWeight: 600, color:'#000', padding: '1rem', borderRadius: '15px', marginTop: '1rem', marginBottom: '1.5rem'}}>Onde gostaria de ir? </label>
                    <ul className="sub-menu">
                    <li><Link to="/" style={{color: '#fff', fontSize: '0.9rem'}}>Início</Link></li>
                        <li><Link to="/como" style={{color: '#fff', fontSize: '0.9rem'}}>Como ajudamos sua empresa</Link></li>
                        <li><Link to="/solucoes" style={{color: '#fff', fontSize: '0.9rem'}}>Soluções</Link></li>
                        <li><Link to="/sobre-nos" style={{color: '#fff', fontSize: '0.9rem'}}>Sobre Nós</Link></li>
                    </ul>
                </li>
            </ul>
        </nav>
        <div className="action-bar">
            <hr />
            <h3 style={{marginTop: '2rem'}}>Gostaria de conversar conosco?</h3>
            <h6 style={{fontSize: '1rem',}}>Clique na opção desejada, estamos aguardando seu contato!</h6>
            <a href="https://wa.me/5511953967716?text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista." target={'_blank'} rel='noreferrer' className="theme-btn wow fadeInLeft" style={{fontSize: '1.1rem', marginTop: '1rem', backgroundColor: '#25D366', width: '100%', textAlign: 'center', borderRadius: '30px'}} data-wow-duration="1.2s" data-wow-delay=".8s">
                <i className="fab fa-whatsapp" style={{marginRight: '0.5rem'}}></i> (11) 9 5396-7716</a>                        
            <a href="https://wa.me/5511983304959?text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista."  target={'_blank'} rel='noreferrer' className="theme-btn wow fadeInLeft" style={{fontSize: '1.1rem', backgroundColor: '#25D366', width: '100%', textAlign: 'center', borderRadius: '30px'}} data-wow-duration="1.2s" data-wow-delay=".8s">
                <i className="fab fa-whatsapp" style={{marginRight: '0.5rem'}}></i> (11) 9 8330-4959</a>
        </div>  
    </div>    
     
  )
}

export default MobileMenu
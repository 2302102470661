import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import slide1 from '../../assets/img/home1/slide1.jpg';

import "swiper/css";
import "swiper/css/navigation";



const HeroOne = () => {
    return (
        <section className="hero-wrapper hero-1">
        <Swiper loop>
            <SwiperSlide className="bg-cover" style={{backgroundImage: `url(${slide1})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                            <div className="hero-contents pe-lg-3" style={{padding: '1rem', paddingTop: '6rem', paddingBottom: '6rem'}}>
                                <h1 className="wow fadeInLeft animated" data-wow-duration="1.3s" style={{color: '#fff'}}>Somos a solução para a sua empresa</h1>
                                <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">Oferecemos soluções para linhas de envase e empacotamento de produtos.</p>
                                <Link to="/contato" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">Quero falar com um especialista</Link>
                                <Link to="/sobre-nos" className="plus-text-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">
                                    <div className="icon">
                                        <i className="fas fa-plus"></i>
                                    </div>
                                    <div className="link-text">
                                        <span>Conheça mais</span> Sobre nós
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </section>
    )
}

export default HeroOne;
import {v4 as uuidv4} from 'uuid';

const servicesOneData = [
    {
        id: uuidv4(),
        icon: 'custom-balanca',
        title: 'Alimentação para balanças & Multi-cabeçotes',
        desc: 'Adequação de layout para melhor aproveitamento e produção das balanças multi-cabeçotes. Projeto para inserção de dois elevadores e construção de plataforma tipo mezanino. Utilizado em industrias de grãos, cereais, congelados.',
    },

    {
        id: uuidv4(),
        icon: 'custom-envase',
        title: 'Linha semi automática de envase',
        desc: 'Composta por mesa giratória de acumulo, envasador de produtos pastosos ou creme, transporte por esteira linear. Tampadora rotativa com elevador de tampas. Utilizado principalmente em industrias alimentícias, farmaceuticas'
        + ' e cosméticos.',
    },

    {
        id: uuidv4(),
        icon: 'custom-transportador',
        title: 'Transportador tipo Espiral',
        desc: 'Melhor solução para ganho de espaço e produtividade, considerando elevações verticais. Ideal para transporte de produtos que precisam ser congelados onde se tem um acúmulo concentrado de produtos até seu congelamento dentro de camaras frias.',
    },

    
]

export default servicesOneData;
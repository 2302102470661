import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/carrousel-about-one/1.png'
import img2 from '../../assets/img/carrousel-about-one/2.png'
import img3 from '../../assets/img/carrousel-about-one/3.png'
import img4 from '../../assets/img/carrousel-about-one/4.png'
import img5 from '../../assets/img/carrousel-about-one/5.png'
import img6 from '../../assets/img/carrousel-about-one/6.png'
import img7 from '../../assets/img/carrousel-about-one/7.png'

const AboutOneData = [
    {
        id: uuidv4(),
        img: img1,
    },
    {
        id: uuidv4(),
        img: img2,
    },    {
        id: uuidv4(),
        img: img3,
    },    {
        id: uuidv4(),
        img: img4,
    },    {
        id: uuidv4(),
        img: img5,
    },    {
        id: uuidv4(),
        img: img6,
    },    {
        id: uuidv4(),
        img: img7,
    },
]

export default AboutOneData;
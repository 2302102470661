import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AboutOne from "../Components/About/AboutOne";
import CtaOne from "../Components/Cta/CtaOne";
import FeaturesOne from "../Components/Features/FeaturesOne";
import FooterOne from "../Components/Footer/FooterOne";
import HeaderOne from "../Components/Header/HeaderOne";
import HeroOne from "../Components/Hero/HeroOne";
import HeroSocials from "../Components/Hero/HeroSocials";
import ServicesOne from "../Components/Services/ServicesOne";
import WorkProcess from "../Components/Work/WorkProcess";
const HomeOne = () => {
    
    let { section } = useParams();

    useEffect(() => {
        handleClickScroll();
    });

    const handleClickScroll = () => {
        const element = document.getElementById(section);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <>
            <HeaderOne/>
            <HeroSocials/>
            <HeroOne/>
            <section id='sobre-nos'>
                <WorkProcess/>
            </section>
            <section id='como'>
                <AboutOne/>
            </section>
            <section id='solucoes'>
                <ServicesOne/>
            </section>
            <section id=''>
                <CtaOne/>
            </section>
            <section id=''>
                <FeaturesOne/>
            </section>     
            <section id='contato'>   
                <FooterOne/>
            </section>
        </>
    )
}

export default HomeOne;
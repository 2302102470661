import React, {useState, useEffect} from 'react';
import { Routes, Route} from 'react-router-dom';  
import Preloader from './Components/Preloader';
import ScrollTopBtn from './Components/ScrollTopBtn';
import HomeOne from './pages/HomeOne';


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <Preloader/>
  } else {
    return (
    <>
      <ScrollTopBtn/>
        <a href="https://wa.me/5511953967716?text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista." class="float" rel='noreferrer' target="_blank">
            <i class="fab fa-whatsapp my-float"></i>
        </a>
      <Routes>
        <Route path="/" element={<HomeOne />}>
          <Route path=":section" element={<HomeOne />} />
        </Route>
      </Routes>
    </>
    )
  }
}

export default App;

import React from 'react'
import mainLogo from '../assets/img/logo-black.png';

const Preloader = () => {
  return (
    <>
        <div id="preloader" className="preloader">
        <div className="animation-preloader">
            <div className="spinner">           
            </div>
            <img src={mainLogo} width={'300px'} alt='H-Pack' />     
        </div>
    </div>
    </>
  )
}

export default Preloader
import { useState } from "react";
import { Link } from "react-router-dom";
import mainLogo from '../../assets/img/logo.png';
import MobileMenu from "./MobileMenu";
import OffsetMenu from "./OffsetMenu";

const HeaderOne = () => {
  const [offset, setOffset] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(true);


  const handleOffset = (e) => {
    e.preventDefault();
    setOffset(!offset);
  }

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  }
  return (
    <>
    <OffsetMenu offset={offset} handleOffset={handleOffset}  />
    <header className="header-wrap header-1" style={{backgroundColor: '#000', position: 'fixed'}}>
        <div className="container d-flex justify-content-between align-items-center">
            <div className="logo">
                <Link to='/'>
                    <img src={mainLogo} width={'180px'} height={'auto'} alt="logo"/>
                </Link>
            </div>
            <div className="header-right-area d-flex">
                <div className="main-menu d-none d-xl-block">
                    <ul style={{color: '#fff'}}>
                        <li><a href="/" style={{color: '#fff', fontSize: '0.9rem'}}>Início</a></li>
                        <li><Link to="/como" style={{color: '#fff', fontSize: '0.9rem'}}>Como ajudamos sua empresa</Link></li>
                        <li><Link to="/solucoes" style={{color: '#fff', fontSize: '0.9rem'}}>Soluções</Link></li>
                        <li><Link to="/sobre-nos" style={{color: '#fff', fontSize: '0.9rem'}}>Sobre Nós</Link></li>
                    </ul>
                </div>
                <div className="header-right-elements d-flex align-items-center justify-content-between">
                    <Link to="/contato" className="theme-btn d-none d-sm-block">Fale com um especialista</Link>
                    <span onClick={handleOffset} className="side-menu-toggle d-none d-xl-block"><i style={{color: '#fff'}} className="fal fa-bars"></i></span>
                    <div className="d-inline-block ms-4 d-xl-none">
                        <div className="mobile-nav-wrap">                    
                            <div id="hamburger" onClick={handleMobileMenu}>
                                <i style={{color: '#fff'}} className="fal fa-bars"></i>
                            </div>
                            <MobileMenu mobileMenu={mobileMenu} handleMobileMenu={handleMobileMenu} />
                        </div>
                        <div className="overlay"></div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    </>
  )
}

export default HeaderOne;
import workProcessData from "./workProcessData";
import missao from "../../assets/img/icons/missao.png";
import valores from "../../assets/img/icons/valores.png";
import visao from "../../assets/img/icons/visao.png";

const WorkProcess = () => {
    return (
        <section className="work-process-section section-padding mtm-30" style={{backgroundColor: '#000',}}> 
        <div className="container">
            <div className="row">
                {
                    workProcessData.map((data) => (
                        <div className="col-md-6 col-lg-4 border-right col-xl-4 col-12" key={data.id}>
                            <div className="single-work-process-item">
                                <div className="icon">
                                    { data.icon === 'custom-visao' || data.icon === 'custom-missao' || data.icon === 'custom-valores' ? 
                                        <img width={'100px'} height={'100px'} src={data.icon === 'custom-valores' ? valores : data.icon === 'custom-missao' ? missao : data.icon === 'custom-visao' ? visao : null} alt='icone'/>
                                      :
                                        <i className={`flaticon-${data.icon}`}></i>
                                    }
                                </div>
                                <div className="contents" style={{color: '#fff'}}>
                                    <span>{data.subTitle}</span>
                                    <h5>{data.title}</h5>
                                    <p style={{whiteSpace: 'pre-line'}}>{ data.desc}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>
    )
}

export default WorkProcess;
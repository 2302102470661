import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/effect-fade';
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { EffectFade } from 'swiper';
import AboutOneData from "./AboutOneData";
import tabImg1 from '../../assets/img/home1/tab-img.jpg'
import 'react-modal-video/scss/modal-video.scss';


const AboutOne = ({pt}) => {

    return (
        <>
        <section style={{paddingTop: '5rem', paddingBottom: '5rem'}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-5 col-12">
                        <Swiper  navigation={true} modules={[Navigation, EffectFade]} effect="fade" loop style={{ "--swiper-pagination-color": "#ffff00", "--swiper-navigation-color": "#ffff00"}}>
                                {
                                    AboutOneData.map((data) => (
                                        <SwiperSlide key={data.id}>
                                            <img src={data.img} style={{borderRadius: '15px'}} width='100%' height='auto' alt={'Imagem de Carrousel Número: ' + data.id} />
                                        </SwiperSlide>
                                    ))
                                }
                        </Swiper>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-10 col-12">
                        <div className="block-contents" style={{paddingTop: '3rem'}}>
                            <div className="section-title">
                                <span>Conheça a H-Pack</span>
                                <h2 style={{color: '#000'}}>SOMOS A SOLUÇÃO PARA SUA EMPRESA</h2>
                            </div>
                            <p style={{color: '#000'}}>Oferecemos soluções para linhas de envase e empacotamento de produtos, 
                            seja para industria alimenticia, farmaceutica, bebidas, cosméticos, automotiva e produção geral.</p>
                        </div>
                        <div className="tab-content-block">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <hr />
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-technology" role="tabpanel" aria-labelledby="pills-technology-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                            <img src={tabImg1} style={{borderRadius: '15px'}} alt=""/>
                                        </div>
                                        <div className="checked-features-list" style={{color: '#fff',}}>
                                            <ul>
                                                <li style={{color: '#000', fontSize: '0.8rem'}}>Qualidade e pontualidade incomparáveis no mercado</li>
                                                <li style={{color: '#000', fontSize: '0.8rem'}}>Transparência durante toda a prestação de serviço</li>
                                                <li style={{color: '#000', fontSize: '0.8rem'}}>Trabalhos com Máquinas padronizadas e projetos conforme sua necessidade</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default AboutOne;
import {v4 as uuidv4} from 'uuid';

const workProcessData = [
    {
        id: uuidv4(),
        icon: 'custom-missao',
        subTitle: 'Missão',
        title: 'Nossa Missão',
        desc: 'Desenvolver máquinas e criar soluções que sejam capazes de aumentar a produção e atender a necessidade dos nossos cliente.',
    },

    {
        id: uuidv4(),
        icon: 'custom-valores',
        subTitle: 'Valores',
        title: 'Nossos Valores',
        desc: 'Ética: ser honesto em todos os aspectos e agindo sempre com transparência. \n Excelência: foco na qualidade e pontulidade dos serviços prestados. \n Foco no cliente: ' + 
        'gerar confiança através dos serviços prestados mantendo a atenção devida com os problemas a serem resolvidos',
    }, 

    {
        id: uuidv4(),
        icon: 'custom-visao',
        subTitle: 'Visão',
        title: 'Nossa Visão',
        desc: 'Ser uma empresa reconhecida por agregar valor ao cliente, sempre agindo com ética e honestidade. Oferencendo soluções que sejam de acordo com as suas necessidades.',
    },       
]

export default workProcessData;
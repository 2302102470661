import mainLogo from '../../assets/img/logo-black.png';

const OffsetMenu = ({offset, handleOffset}) => {
    return (
        <>
        <div className={offset? 'offset-menu': 'offset-menu show'}>
            <span onClick={handleOffset} id="offset-menu-close-btn"><i className="fal fa-plus"></i></span>
            <div className="offset-menu-wrapper text-white">
                <div className="offset-menu-header">
                    <img src={mainLogo} alt="logo"/>
                </div> 
                <div className="offset-menu-section">
                    <h3 style={{color: '#161616'}}>Gostaria de conversar conosco?</h3>
                    <h6 style={{fontSize: '1rem',}}>Clique na opção desejada, estamos aguardando seu contato!</h6>
                    <a href="https://wa.me/5511953967716?text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista." target={'_blank'} rel='noreferrer' className="theme-btn wow fadeInLeft" style={{fontSize: '1.1rem', marginTop: '1rem', backgroundColor: '#25D366', width: '100%', textAlign: 'center', borderRadius: '30px'}} data-wow-duration="1.2s" data-wow-delay=".8s">
                        <i className="fab fa-whatsapp" style={{marginRight: '0.5rem'}}></i> (11) 9 5396-7716</a>                        
                    <a href="https://wa.me/5511983304959?text=Ol%C3%A1,%20gostaria%20de%20falar%20com%20um%20especialista."  target={'_blank'} rel='noreferrer' className="theme-btn wow fadeInLeft" style={{fontSize: '1.1rem', backgroundColor: '#25D366', width: '100%', textAlign: 'center', borderRadius: '30px'}} data-wow-duration="1.2s" data-wow-delay=".8s">
                        <i className="fab fa-whatsapp" style={{marginRight: '0.5rem'}}></i> (11) 9 8330-4959</a>
                </div>   
            </div>               
        </div>
        </>
    )
}

export default OffsetMenu;
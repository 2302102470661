import { Link } from "react-router-dom";
import FooterOneCopyrights from "./FooterOneCopyrights";
import footerLogo from '../../assets/img/logo.png';

const FooterOne = () => {
    return (
        <>
            <footer className="footer-1 footer-wrap">
                <div className="footer-widgets-wrapper">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-3 pe-xl-1 offset-xl-1 col-sm-6 col-12 footer-cta-wrapper" style={{backgroundColor: 'transparent', paddingTop: '2.5rem'}}>                        
                                <div className="footer-logo">
                                    <a href="index.html">
                                        <img src={footerLogo} width={'200px'} alt="logo"/>
                                    </a>
                                </div>
                                <div className="footer-middle-text text-white" style={{marginTop: '1.5rem'}}>
                                    <p>Oferecemos soluções para linhas de envase e empacotamento de produtos, seja para industria alimenticia, 
                                        farmaceutica, bebidas, cosméticos, automotiva e produção geral.</p>
                                </div>
                                <div className="footer-social-icon" style={{marginTop: '2rem', textAlign: 'initial'}}>
                                    <a href="https://www.facebook.com/profile.php?id=100088733146088" rel="noreferrer" target={'_blank'}><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/hpackmaquinas/" rel="noreferrer" target={'_blank'}><i className="fab fa-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/h-packmaquinas/about/?viewAsMember=true" rel="noreferrer" target={'_blank'}><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div> 
                            <div className="col-xl-3 pe-xl-1 offset-xl-1 col-sm-6 col-12">
                                <div className="single-footer-wid site_info_widget">
                                    <div className="wid-title">
                                        <h3>Fale com um especialista</h3>
                                    </div>
                                    <div className="contact-us">
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fab fa-whatsapp"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p><a href="https://wa.me/5511953967716?text=Olá,%20gostaria%20de%20falar%20com%20um%20especialista." style={{color: '#f5f5f5'}} rel="noreferrer" target={'_blank'}>(11) 9 5396-7716</a></p>
                                                <p><a href="https://wa.me/5511983304959?text=Olá,%20gostaria%20de%20falar%20com%20um%20especialista." style={{color: '#f5f5f5'}} rel="noreferrer" target={'_blank'}>(11) 9 8330-4959</a></p>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-envelope"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p><a href="mailto:contato@hpackmaquinas.com.br" rel="noreferrer" target={'_blank'} style={{color: '#f5f5f5'}}>contato@hpackmaquinas.com.br</a></p>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-envelope"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p><a href="mailto:engenharia@hpackmaquinas.com.br" rel="noreferrer" target={'_blank'} style={{color: '#f5f5f5', fontSize: '0.9rem'}}>engenharia@hpackmaquinas.com.br</a></p>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-envelope"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p><a href="mailto:vendas@hpackmaquinas.com.br" rel="noreferrer" target={'_blank'} style={{color: '#f5f5f5'}}>vendas@hpackmaquinas.com.br</a></p>
                                            </div>
                                        </div>
                                        <div className="single-contact-info">
                                            <div className="icon">
                                                <i className="fal fa-map-marker-alt"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p><a rel="noreferrer" target={'_blank'} href="https://www.google.com/maps/place/R.+Jequié,+12+-+Jardim+Thelma,+São+Bernardo+do+Campo+-+SP,+09855-015/@-23.7341827,-46.5996191,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce46ae5c131c11:0xe54ca99959aaa0f1!8m2!3d-23.7341827!4d-46.5996191!16s%2Fg%2F11cp99_5tm" style={{color: '#f5f5f5', fontSize: '0.8rem'}}>Rua Jequié, 12 - Cooperativa  <br/>
                                                    São Bernardo do Campo/SP <br />
                                                    CEP: 09855-015</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 ps-xl-1 offset-xl-1 col-sm-6 col-12">
                                <div className="single-footer-wid">
                                    <div className="wid-title">
                                        <h3>Links Rápidos</h3>
                                    </div>
                                    <ul>
                                        <li><Link to="/">Início</Link></li>
                                        <li><Link to="/como">Como ajudamos sua empresa</Link></li>
                                        <li><Link to="/solucoes">Soluções</Link></li>
                                        <li><Link to="/sobre">Sobre nós</Link></li>
                                        <li><Link to="/contato">Fale com um especialista</Link></li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <FooterOneCopyrights/>
            </footer> 
        </>
    )
}

export default FooterOne;